<template>
  <div class="time-line">
    <div v-for="(item, index) in items" :key="item.id" class="timeline">
      <div class="circle-line">
        <div class="circle" />
        <div v-if="!isLast(index)" class="line" />
      </div>
      <div class="content">
        <p class="title">{{ item.title }}</p>
        <p class="dates">{{ item.dates }}</p>
        <p class="detail">{{ item.detail }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isLast(index) {
      return index === this.items.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  display: flex;
  justify-content: flex-start;

  .circle-line {
    display: flex;
    flex-flow: column;
    align-items: center;

    .circle {
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $blue;
      box-shadow: #cbd3ff 0 1px 4px, #cbd3ff 0 0 0 3px;
    }

    .line {
      width: 1px;
      height: 100%;
      background-color: #f0f0f6;
    }
  }

  .content {
    margin-left: 20px;
    margin-top: -8px;
    max-width: 350px;

    .title {
      font-weight: 500;
      font-size: 18px;
      margin: 0;
    }

    .dates {
      color: #8f9faf;
      font-size: 14px;
      margin-top: 5px;
    }

    .detail {
      font-size: 14px;
      margin: 10px 0 40px 0;
    }
  }
}
</style>
