<template>
  <div class="resume">
    <section>
      <content-title name="Resume" />
      <bar />
    </section>
    <section class="education-experience">
      <div class="experience">
        <br />
        <content-sub-title name="Experience" />
        <br />
        <timeline class="ml10" :items="experiences" />
      </div>
      <div class="education">
        <br />
        <content-sub-title name="Education" />
        <br />
        <timeline class="ml10" :items="educations" />
      </div>
    </section>
    <section class="skills flex space-between">
      <div class="coding-skills">
        <content-sub-title name="Coding Skills" />
        <el-tag
          v-for="codeSkill in codingSkills"
          class="m5"
          :key="codeSkill"
          type="primary"
          >{{ codeSkill }}</el-tag
        >
      </div>
      <div class="interpersonal-skills">
        <content-sub-title name="Interpersonal Skills" />
        <el-tag
          v-for="interpersonalSkill in interpersonalSkills"
          class="m5"
          :key="interpersonalSkill"
          type="primary"
          >{{ interpersonalSkill }}</el-tag
        >
      </div>
    </section>
    <div class="footer">
      <footer-content />
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/contents/Title";
import ContentSubTitle from "@/components/contents/SubTitle";
import Bar from "@/components/contents/Bar.vue";
import FooterContent from "@/components/contents/Footer.vue";
import Timeline from "@/components/Timeline.vue";

export default {
  name: "Resume",
  components: {
    ContentTitle,
    ContentSubTitle,
    Bar,
    FooterContent,
    Timeline,
  },
  data() {
    return {
      educations: [
        {
          id: 1,
          title: "Power MBA",
          dates: "2019 — 2020",
          detail: "Master of Business Administration - MBA",
        },
        {
          id: 2,
          title: "Universidad ORT Uruguay",
          dates: "2010 — 2017",
          detail:
            "For the thesis I created a medical device prototype that relied on artificial intelligence to improve diabetes patient lifes quality.",
        },
        {
          id: 3,
          title: "Bios",
          dates: "2009 — 2009",
          detail: "Computer and Network Technician at Bios",
        },
      ],
      experiences: [
        {
          id: 1,
          title: "Senior Backend Developer at Housfy",
          dates: "Since 2019",
          detail: "",
        },
        {
          id: 2,
          title: "Full Stack Developer at Oh my Brand",
          dates: "2018 — 2019",
          detail:
            "I worked as a Full Stack Developer in a wide variety of projects ranging from E-commerce development to digital workplace design. The main technology toolset I relied on included Vue.js and Laravel. My solid database and PHP experience was essential to cope with new challenges and craft and tweak every aspect of the final product to better fit the customer's needs.",
        },
        {
          id: 3,
          title: "Full Stack Developer at TATETI",
          dates: "2015 — 2017",
          detail:
            "TATETI offers an entire set of services to manage restaurant reservations. My job was to create the web application from backend to frontend. I was also responsible for selling the product and identifying the restaurant’s needs.",
        },
        {
          id: 4,
          title: "Full Stack Developer at Artidea",
          dates: "2016 — 2017",
          detail:
            "I developed a platform to manage stock, sales and customers. It automatizes internal communication between owners and sales staff, all combined into an overarching solution.",
        },
        {
          id: 5,
          title: "SQL Developer at Top Systems",
          dates: "2014 — 2016",
          detail:
            "I developed an efficient anti money laundering systems specifically tailored to financial authorities and banks. I was in charge of the team that deployed the software at the customers company. Furthermore, I acquired invaluable knowledge and insights about banking processes and methods to prevent money laundry.",
        },
      ],
      codingSkills: [
        "Microsoft SQL Server",
        "Oracle SQL",
        "MySql",
        "Redshift",
        "Stitch",
        "AWS Services",
        "HTML",
        "CSS",
        "Javascript",
        "Vue.js",
        "React.js",
        "C#",
        "Java",
        "PHP",
        "Laravel",
        "Domain Driven Design",
        "UNIX",
        "Jira",
        "Git",
      ],
      interpersonalSkills: ["Design Thinking", "Manage Teams"],
    };
  },
};
</script>

<style lang="scss" scoped>
.resume {
  section {
    padding: 0 4%;
  }

  .education-experience {
    display: flex;
    justify-content: space-between;
  }

  .skills {
    background-color: $grey;
    padding: 3% 4%;

    .coding-skills {
      width: 60%;
    }

    .interpersonal-skills {
      width: 40%;
    }
  }

  .footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @media (max-width: 1100px) {
    .education-experience {
      display: flex;
      flex-flow: column;
    }

    .skills {
      display: flex;
      flex-flow: column;

      .coding-skills {
        width: 100%;
      }

      .interpersonal-skills {
        width: 100%;
      }
    }
  }
}
</style>
